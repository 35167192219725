import styled from "styled-components"
import { Grid } from "@material-ui/core"
import SectionWrapper from "../../components/section-wrapper/section-wrapper.component"
import SecondaryBlock from "../../components/secondary-block/secondary-block.component"

export const Wrapper = styled(SectionWrapper)`
  padding-top: 10em;
  padding-bottom: 6em;

  background-color: #f7f1ea;

  & > .MuiContainer-root {
    max-width: 1050px;
  }

  .contain {
  }

  h1 {
    font-size: 3.2em;
    line-height: 1.1em;
    margin-bottom: 0.5em;
  }

  h2 {
    font-size: 2.5em;
    margin-top: 1em;
  }
  strong {
    font-weight: 700;
  }

  p {
    overflow: hidden;
  }

  ul,
  ol {
    font-size: 1.1rem;
  }

  .wp-block-image {
    margin: 0;

    ${({ theme }) => theme.breakpoints.down("sm")} {
      .gatsby-image-wrapper {
        width: 90vw !important;
      }
    }
  }
`

export const Sidebar = styled(Grid)`
  .secondary_block {
    padding: 0;

    [data-placeholder-image] {
      background-color: transparent !important;
    }

    & > .image {
      height: auto;
      background-color: transparent;
    }

    & > .content {
      padding: 0 10px 10px;
      background-color: transparent;

      .subtitle {
        margin-top: 1em;
        font-size: 1.2em;
        border-bottom-color: #1e13996b;
      }

      .description {
        font-size: 0.8rem;
      }

      a.roundedButton {
        font-size: 0.9rem;
        display: block;
        text-align: center;
      }
    }
  }
`

export const Block = styled(SecondaryBlock)`
  padding: 0 !important;

  & > .image {
    height: auto;
  }
`
