import styled from "styled-components"

export const CustomContainer = styled.div`
  display: flex;
  flex-direction: column;
  padding: 0 18px;
  margin-bottom: 36px;

  & > .image {
    height: 30vw;

    ${({ theme }) => theme.breakpoints.down("sm")} {
      height: auto;
    }
  }

  & > .content {
    background-color: #fff;
    padding: 30px 25px;
    flex-grow: 1;
    text-align: left;

    .title {
      font-size: ${({ theme }) => theme.typography.pxToRem(34)};
      font-family: ${({ theme }) => theme.fonts.primary};
      font-weight: bold;
      text-align: left;
      margin: 10px 0 0;

      a { color: inherit; text-decoration: none; }
    }

    .subtitle {
      font-size: ${({ theme }) => theme.typography.pxToRem(30)};
      font-family: ${({ theme }) => theme.fonts.primary};
      font-weight: bold;
      margin: 0 0 15px 0;
      padding-bottom: 10px;
      border-bottom: 1px solid #000;
    }

    .description {
      font-size: ${({ theme }) => theme.typography.pxToRem(20)};
      font-family: ${({ theme }) => theme.fonts.tertiary};
      color: #000;
    }

  }
`

export const ImageContainer = styled.div`
  overflow: hidden;
  height: 100%;

  .imageWrapper { height: 100%; }

  .gatsby-image-wrapper {
    img {
      object-fit: contain !important;
    }
  }
`