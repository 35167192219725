import React from "react"
import { graphql, useStaticQuery } from "gatsby"
import Layout from "../../components/layout"
import { Grid } from "@material-ui/core"
import * as S from "./post.styles.jsx"
// import Img from "gatsby-image"

const Post = ({ pageContext: { data } }) => {
  const globalLayoutsQuery = useStaticQuery(graphql`
    query {
      allWp {
        nodes {
          globalLayouts {
            globalLayoutsFields {
              blogSidebar {
                ... on WpGlobalLayouts_Globallayoutsfields_BlogSidebar_SecondaryBlocks {
                  fieldGroupName
                  items {
                    button {
                      target
                      title
                      url
                    }
                    description
                    isActive
                    title
                    image {
                      altText
                      localFile {
                        childImageSharp {
                          gatsbyImageData(width: 900, layout: FULL_WIDTH)
                        }
                      }
                      sourceUrl
                    }
                  }
                }
              }
              promoBarArea {
                ... on WpGlobalLayouts_Globallayoutsfields_PromoBarArea_PromoBar {
                  content
                  fieldGroupName
                  isActive
                }
              }
            }
          }
        }
      }
    }
  `)

  const sidebarData =
    globalLayoutsQuery.allWp.nodes[0].globalLayouts.globalLayoutsFields
      .blogSidebar[0]
  const promoBarData =
    globalLayoutsQuery.allWp.nodes[0].globalLayouts.globalLayoutsFields
      .promoBarArea[0]

  let sidebarWidgets = []
  sidebarData.items.forEach(el => {
    if (el.isActive) {
      let widget = {
        subtitle: el.title,
        description: el.description,
        image: el.image.localFile,
        button: el.button,
      }
      sidebarWidgets.push(widget)
    }
  })

  const { seo, title, content } = data

  return (
    <Layout
      seo={seo}
      layoutType="post"
      promobar={promoBarData}
      showHeaderBackground={true}
    >
      <S.Wrapper contained>
        <Grid container justifyContent="center" spacing={10}>
          <Grid item md={8} sm={12}>
            <h1>{title}</h1>
            <div dangerouslySetInnerHTML={{ __html: content }} />
            {/*<Img fluid={featuredImage.imageFile.childImageSharp.fluid} />*/}
          </Grid>
          <S.Sidebar item md={4} sm={12}>
            {sidebarWidgets.map((item, index) => (
              <S.Block item={item} key={index} />
            ))}
          </S.Sidebar>
        </Grid>
      </S.Wrapper>
    </Layout>
  )
}

export default Post
