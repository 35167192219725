import React from "react"
import * as S from "./secondary-block.styles"
import parse from "html-react-parser"
import CustomImage from "../custom-image/custom-image.component"
import RoundedButton from "../rounded-button/rounded-button.component"

const SecondaryBlock = ({item, key}) => {
  return (
    <S.CustomContainer className="secondary_block" key={key}>
       
        <S.ImageContainer className="image">
        {(item.button && item.image && (
            <a href={item.button.url} target={item.button.target}>
                <CustomImage
                    className="imageWrapper"
                    alt={item.image.altText}
                    img={item.image}
                />
            </a>
        )) || (
            item.image && (
                <CustomImage
                    className="imageWrapper"
                    alt={item.image.altText}
                    img={item.image}
                />
            )
        )}
        </S.ImageContainer>

        <div className="content">
            {item.title && (
                <h4 className="title">
                    <a href={item.button.url} target={item.button.target}>
                        {parse(item.title)}
                    </a>
                </h4>
            )}
            {item.subtitle && (
                <h5 className="subtitle">
                    {parse(item.subtitle)}
                </h5>
            )}

            {item.description && (
                <p className="description">
                    {parse(item.description)}
                </p>
            )}

            {item.button && (
            <RoundedButton url={item.button.url} target={item.button.target} className="roundedButton">
                {item.button.title}
            </RoundedButton>
            )}
        </div>
    </S.CustomContainer>
  )
}

export default SecondaryBlock