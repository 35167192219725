import styled from "styled-components"
import CustomLink from "../custom-link/custom-link.component"

export const RoundedButton = styled(CustomLink)`
  background-color: #1e1499;
  color: #fff;
  font-family: ${({ theme }) => theme.fonts.primary};
  font-size: ${({ theme }) => theme.typography.pxToRem(23)};
  font-weight: 700;
  display: inline-block;
  padding: 10px 30px 7px;
  border-radius: 30px;
  text-transform: uppercase;

  &:hover {
    background-color: #2b1dd5;
  }
`